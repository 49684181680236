<template>
    <section class="events-list">
        <div v-if="slice.variation === 'default'" class="content">
            <div class="top-row">
                <PrismicRichText :field="slice.primary.title" class="title" />
                <NuxtLink v-if="!breakpoints?.isTablet" v-slot="{href, navigate}" to="/resources/events" custom>
                    <VJoyButton variant="primary" :href="href" @click="navigate">{{ t('resources.events.seeAll') }}</VJoyButton>
                </NuxtLink>
            </div>
            <template v-if="breakpoints?.isTablet">
                <Timers v-if="breakpoints?.isTablet" :current-slide="currentSlide" :slide-to="slideTo" :length="events.length" />
            </template>
            <div class="events">
                <Swiper
                    :modules="MODULES"
                    effect="fade"
                    :fade-effect="{crossFade: true}"
                    :loop="true"
                    :slides-per-view="1"
                    :allow-touch-move="false"
                    @swiper="initAutoplay"
                >
                    <SwiperSlide v-for="event in events" :key="event.uid" class="event">
                        <EventThumbnail :event="event" class="image" />
                        <div class="event-info">
                            <CategoryPill :category="event.data?.category ?? null" class="category" color="white" />
                            <h3 class="event-title">{{ event.data?.title }}</h3>
                            <div class="tags">
                                <TagPill v-for="tag in event.tags" :key="tag" :tag="tag" color="white" />
                            </div>
                            <PrismicLink
                                v-if="(isBefore(new Date(), new Date(event.data?.date as string)) || isSameDay(new Date(), new Date(event.data?.date as string)))"
                                :field="event.data?.signupLink ?? {link_type: 'Any'}"
                                class="link"
                            >
                                {{ t('resources.events.signup') }}
                            </PrismicLink>
                            <PrismicLink
                                v-else-if="isFilled.link(event.data?.replayLink)"
                                :field="(event.data?.replayLink as LinkField)"
                                class="link"
                            >
                                {{ t('resources.events.watchReplay') }}
                            </PrismicLink>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
            <template v-if="!breakpoints?.isTablet">
                <Timers v-if="!breakpoints?.isTablet" :current-slide="currentSlide" :slide-to="slideTo" :length="events.length" />
            </template>
            <NuxtLink v-if="breakpoints?.isTablet" v-slot="{href, navigate}" to="/resources/events" custom>
                <VJoyButton class="mobile-cta" variant="primary" :href="href" @click="navigate">{{ t('resources.events.seeAll') }}</VJoyButton>
            </NuxtLink>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {ref, inject, onBeforeUnmount} from 'vue';
    import {PrismicLink, PrismicRichText} from '@prismicio/vue';
    import type {EventDocument, EventDocumentData, EventsListSliceDefault} from '@prismicio-types';
    // eslint-disable-next-line @typescript-eslint/consistent-type-imports
    import type {FilledContentRelationshipField, LinkField} from '@prismicio/types';
    import {isFilled} from '@prismicio/helpers';
    import {predicate} from '@prismicio/client';
    import {useAsyncData, useTranslation} from '#imports';
    import usePrismicSearch from '~/composables/content/usePrismicSearch';
    import {Swiper, SwiperSlide} from 'swiper/vue';
    // @ts-ignore
    import type {Swiper as SwiperType} from 'swiper';
    // @ts-ignore
    import {EffectFade, Controller} from 'swiper';
    import 'swiper/css';
    import EventThumbnail from '~/components/EventThumbnail.vue';
    import CategoryPill from '~/components/CategoryPill.vue';
    import TagPill from '~/components/TagPill.vue';
    import {useDateHelpers} from '~/composables/useDateHelpers';
    import Timers from './Timers.vue';
    import {MEDIA_BREAKPOINTS} from '~/app/keys';
    import {VJoyButton} from '@maltjoy/core-vue';

    const MODULES = [Controller, EffectFade];
    const breakpoints = inject(MEDIA_BREAKPOINTS);
    const props = defineProps<{slice: EventsListSliceDefault}>();
    const events = [props.slice.primary.event1, props.slice.primary.event2, props.slice.primary.event3].filter((article) =>
        isFilled.contentRelationship<'event', string, EventDocumentData>(article),
    ) as (FilledContentRelationshipField<'event', string, EventDocumentData> | EventDocument)[];

    const {t} = useTranslation();
    const {isBefore, isSameDay} = useDateHelpers();

    const swiperRef = ref<SwiperType>();
    const swiperInterval = ref<NodeJS.Timeout>();
    const swiperTimeout = ref<NodeJS.Timeout>();
    const currentSlide = ref(-1);
    function initAutoplay(swiper: SwiperType) {
        swiperRef.value = swiper;
        slideTo(0);
    }
    onBeforeUnmount(() => {
        if (swiperInterval.value) {
            clearInterval(swiperInterval.value);
        }
        if (swiperTimeout.value) {
            clearTimeout(swiperTimeout.value);
        }
        if (swiperRef.value) {
            swiperRef.value.destroy();
        }
    });
    function transitionTo(index: number) {
        currentSlide.value = index - 1;
        if (swiperTimeout.value) {
            clearTimeout(swiperTimeout.value);
        }
        swiperTimeout.value = setTimeout(() => {
            swiperRef.value?.slideTo(index + 1);
            currentSlide.value = index;
        }, 100); // reducing the value + timeout is required to clear the progressbar bar
    }
    function slideTo(index: number) {
        if (swiperInterval.value) {
            clearInterval(swiperInterval.value);
        }
        transitionTo(index);
        swiperInterval.value = setInterval(() => {
            transitionTo((currentSlide.value + 1) % events.length);
        }, 3900);
    }

    function fetchUpcomingEvents() {
        if (events.length < 3) {
            // prismic does not allow 'or' queries, so we have to do multiple queries
            const upcomingLimitDate = new Date();
            upcomingLimitDate.setTime(upcomingLimitDate.getTime() - 1000 * 60 * 60 * 24); // yesterday
            const upcomingPredicates = [predicate.dateAfter('my.event.date', upcomingLimitDate.toISOString().split('T')[0])]; // YYYY-MM-DD
            const upcomingEventQueries = props.slice.items
                .filter((searchFilter) => searchFilter.categoryFilter || searchFilter.categoryFilter)
                .map((item) =>
                    usePrismicSearch({
                        type: 'event',
                        pageSize: 3 - events.length,
                        category: item.categoryFilter ?? undefined,
                        tags: item.tagFilters?.split(', '),
                        excludes: events.map((event) => event.id),
                        orderings: [{field: 'my.event.date', direction: 'asc'}],
                        predicates: upcomingPredicates,
                    }),
                );
            if (upcomingEventQueries.length === 0) {
                upcomingEventQueries.push(
                    usePrismicSearch({
                        type: 'event',
                        pageSize: 3 - events.length,
                        excludes: events.map((event) => event.id),
                        orderings: [{field: 'my.event.date', direction: 'asc'}],
                        predicates: upcomingPredicates,
                    }),
                );
            }
            return Promise.all(upcomingEventQueries);
        }
        return Promise.resolve([]);
    }

    function fetchPastEvents() {
        if (events.length < 3) {
            const passedPredicate = [predicate.dateBefore('my.event.date', new Date().toISOString().split('T')[0])]; // YYYY-MM-DD

            const pastEventQueries = props.slice.items
                .filter((searchFilter) => searchFilter.categoryFilter || searchFilter.categoryFilter)
                .map((item) =>
                    usePrismicSearch({
                        type: 'event',
                        pageSize: 3 - events.length,
                        category: item.categoryFilter ?? undefined,
                        tags: item.tagFilters?.split(', '),
                        excludes: events.map((event) => event.id),
                        orderings: [{field: 'my.event.date', direction: 'desc'}],
                        predicates: passedPredicate,
                    }),
                );
            if (pastEventQueries.length === 0) {
                pastEventQueries.push(
                    usePrismicSearch({
                        type: 'event',
                        pageSize: 3 - events.length,
                        excludes: events.map((event) => event.id),
                        orderings: [{field: 'my.event.date', direction: 'desc'}],
                        predicates: passedPredicate,
                    }),
                );
            }
            return Promise.all(pastEventQueries);
        }
        return Promise.resolve([]);
    }

    const {data: complementaryUpcomingEvent} = await useAsyncData('upcoming-events', fetchUpcomingEvents);
    const {data: complementaryPastEvent} = await useAsyncData('past-events', fetchPastEvents);

    if (complementaryUpcomingEvent.value && complementaryPastEvent.value) {
        const upcomingEvents = complementaryUpcomingEvent.value
            .map((query) => query?.results)
            .flat()
            .filter((event) => event !== undefined);
        const pastEvents = complementaryPastEvent.value
            .map((query) => query?.results)
            .flat()
            .filter((event) => event !== undefined);
        const complementaryEvents = [...upcomingEvents, ...pastEvents].slice(0, 3 - events.length) as EventDocument[];
        events.push(...complementaryEvents);
    }
</script>

<style lang="scss">
    @use '~/assets/styles/variables' as *;
    .events-list {
        background-color: var(--joy-color-primary-50);
        padding: 100px 0;
        .content {
            @include content-width;
        }
        //fix swiper bug:
        .swiper-slide:not(.swiper-slide-active) {
            pointer-events: none;
        }
        .top-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: var(--joy-core-spacing-12);
            .title h2 {
                color: white;
            }
            @media (max-width: $tablet-breakpoint) {
                margin-bottom: var(--joy-core-spacing-9);
            }
        }
        .event {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            gap: 20px;
            transition: opacity var(--joy-transition-duration-long) var(--joy-transition-timing-function);
            @media (max-width: $tablet-breakpoint) {
                flex-direction: column;
                gap: var(--joy-core-spacing-4);
            }
            .image {
                flex-basis: 57%;
                min-height: 550px;
                @media (max-width: $tablet-breakpoint) {
                    width: 100%;
                    flex-basis: unset;
                }
                @media (max-width: $mobile-breakpoint) {
                    min-height: 370px;
                }
            }
            .event-info {
                flex-basis: 35%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                *:not(.category-pill a):not(.category-pill span) {
                    color: white;
                }
                .category-pill {
                    margin-bottom: var(--joy-core-spacing-7);
                    @media (max-width: $tablet-breakpoint) {
                        margin-bottom: var(--joy-core-spacing-5);
                    }
                }
                .event-title {
                    font-size: 60px;
                    font-weight: 800;
                    line-height: 100%;
                    margin-bottom: var(--joy-core-spacing-4);
                    @media (max-width: $tablet-breakpoint) {
                        font-size: 35px;
                    }
                }
                .tags {
                    display: flex;
                    gap: var(--joy-core-spacing-1);
                    margin-bottom: var(--joy-core-spacing-8);
                    flex-wrap: wrap;
                    @media (max-width: $tablet-breakpoint) {
                        margin-bottom: var(--joy-core-spacing-5);
                    }
                }
                .link {
                    @include link;
                    color: white;
                    border-color: white;
                    &:hover {
                        color: var(--joy-color-neutral-50) !important;
                        border-color: var(--joy-color-neutral-50);
                    }
                }
            }
        }
        .timers {
            display: flex;
            gap: var(--joy-core-spacing-9);
            margin-top: var(--joy-core-spacing-12);
            @media (max-width: $tablet-breakpoint) {
                margin-top: 0;
                margin-bottom: var(--joy-core-spacing-7);
            }
            .timer {
                flex-basis: 33%;
                max-width: 180px;
                text-align: start;
                .timer-number {
                    font-family: var(--joy-font-family-title);
                    font-size: var(--joy-font-size-secondary-200);
                    font-weight: 800;
                    color: white;
                }
                .progress-bar {
                    margin-top: var(--joy-core-spacing-4);
                    background-color: rgba(white, 0.2);
                    height: 4px;
                    width: 100%;
                    overflow: hidden;
                    .progress {
                        background-color: white;
                        height: 100%;
                        width: 100%;
                        transform: translateX(-100%);
                        &.progressing {
                            transition: transform 4s linear;
                            transform: translateX(0%);
                        }

                        &.done {
                            transition: none;
                            transform: translateX(0%);
                        }
                    }
                }
            }
        }
        .mobile-cta {
            margin-top: var(--joy-core-spacing-12);
        }
    }
</style>
